export default {
  stlViewer: {
    title: {
      ru: "3D VIEWER",
    },
    description: {
      ru: "Просмотр 3D моделей <br> формата STL",
    },
  },
};
