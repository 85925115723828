<template>
  <div id="stlViewer">
    <mainMenu></mainMenu>
    <div class="headerPreview">
      <div class="wrapper">
        <div class="sectionInfo">
          <h1 class="sectionTitle" v-html="translate.stlViewer.title[currentLang]"></h1>
          <h2 class="sectionDescription" v-html="translate.stlViewer.description[currentLang]"></h2>
          <router-link :to="{ name: 'viewer3Dlocal' }" class="btnAbout">
            Перейти к просмотру модели
          </router-link>
        </div>
      </div>
    </div>
    <section id="stl_about">
      <div class="wrapper">
        <h3 class="pageSectionTitle">Самый удобный и быстрый способ просматривать STL файлы</h3>
        <p class="textBold">
          STL viewer позволяет просматривать 3D файлы формата STL с Вашего компьютера без скачивания дополнительного
          программного обеспечения прямо на сайте. Такой подход является инновационным и самым удобным. Пользователь, с
          любым уровнем знаний в использовании ПК, и с любого устройства, имеет возможность при помощи сайта DENTABULA
          просмотреть модели в самом распространенном 3D формате. Данный функционал позволяет изучать одновременно
          несколько объектов. Для большей наглядности в STL viewer есть возможность менять прозрачность и цвет моделей.
        </p>
      </div>
    </section>
    <section id="stl_about_scheme">
      <img src="./../../assets/images/aboutLoadStlBlock.png" alt="" />
    </section>
    <section id="stl_about_controls">
      <div class="wrapper">
        <h3 class="pageSectionTitle">Схема управления мышью</h3>
        <img src="./../../assets/images/demoModels/Mouse_control_scheme.png" class="controls_img" alt="" />
      </div>
    </section>
    <section id="stl_about_colors">
      <div class="wrapper">
        <h3 class="pageSectionTitle">Цвета моделей</h3>
        <div class="stlFlex-container">
          <div class="container">
            <img class="color_img" src="./../../assets/images/aboutLoadStlBlock_collors_1.png" alt="" />
            <img class="color_sub_img" src="./../../assets/images/aboutLoadStlBlock_collors_sub_1.png" alt="" />
          </div>
          <div class="container">
            <img class="color_img" src="./../../assets/images/aboutLoadStlBlock_collors_2.png" alt="" />
            <img class="color_sub_img" src="./../../assets/images/aboutLoadStlBlock_collors_sub_2.png" alt="" />
          </div>
          <div class="container">
            <img class="color_img" src="./../../assets/images/aboutLoadStlBlock_collors_3.png" alt="" />
            <img class="color_sub_img" src="./../../assets/images/aboutLoadStlBlock_collors_sub_3.png" alt="" />
          </div>
          <div class="container">
            <img class="color_img" src="./../../assets/images/aboutLoadStlBlock_collors_4.png" alt="" />
            <img class="color_sub_img" src="./../../assets/images/aboutLoadStlBlock_collors_sub_4.png" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section id="stl_about_video">
      <div class="wrapper">
        <div class="controls_video">
          <iframe src="https://www.youtube.com/embed/xLPhDA6Mc8c" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </section>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";
import translate from "./translate.js";

export default {
  name: "StlViewer",
  components: { mainMenu, mainFooter },
  data() {
    return {
      translate: translate,
    };
  },
  computed: {
    currentLang() {
      return this.$store.getters.currentLang;
    },
  },
};
</script>

<style lang="sass">
#stlViewer
	display: flex
	flex-direction: column
	min-height: calc(100vh - 155px)
	& .headerPreview
		display: flex
		min-height: 270px
		align-items: center
		padding: 0 2rem
		background: url('./../../assets/images/viewer3Dbackground.png')
		background-size: cover
		background-position: center center
		& .sectionInfo
			width: fit-content
			@media screen and (max-width: 426px)
				display: flex
				flex-direction: column
				align-items: center
				width: 100%
				text-align: center
			& .sectionTitle
				color: #00deff
				font-size: 2.5rem
				padding-bottom: 1.5rem
				margin: 0 0 1.5rem
				border-bottom: 1px solid #fff568
			& .sectionDescription
				font-family: 'Ropa Sans Pro SC Regular'
				font-weight: 400
				font-size: 1.5rem
				color: #ffbbf1
				margin: 0 0 1.5rem
			& .btnAbout
				background: #00deff
				color: #fff
				display: block
				padding: 0.5rem 2rem
				text-align: center
				border: 1px solid transparent
				cursor: pointer
#stl_about
	padding: 2rem 1rem
#stl_about_scheme
	display: block
	width: 100%
	height: 785px
	@media screen and (max-width: 1100px)
		height: 600px
	@media screen and (max-width: 880px)
		height: 400px
	@media screen and (max-width: 420px)
		height: 200px
	img
		display: block
		width: 100%
		height: 100%
		object-fit: cover

#stl_about_controls
	padding: 2rem 1rem
	& .controls_img
		display: block
		width: 100%
		max-width: 770px
		height: auto
		margin: 0 auto 2rem

#stl_about_colors
	padding: 2rem 1rem
	background-color: #e1e1e1
	& .stlFlex-container
		display: flex
		flex-wrap: wrap
		justify-content: center
		& .container
			display: flex
			width: calc(100% / 4)
			flex-direction: column
			align-items: center
			padding: 0 1rem
			@media screen and (max-width: 420px)
				width: calc(100% / 2)
			& .color_img
				display: block
				width: 100%
				max-width: 125px
				margin-bottom: 4rem
			& .color_sub_img
				display: block
				width: 100%
				height: auto
				@media screen and (max-width: 420px)
					margin-bottom: 2rem


#stl_about_video
	padding: 2rem 1rem
	& .controls_video
		position: relative
		padding-bottom: 65.25%
		padding-top: 30px
		height: 0
		overflow: auto
		-webkit-overflow-scrolling: touch
		border: solid black transparent
		& iframe
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
</style>
